import React, { memo, useCallback } from "react"
import { Box, HStack } from "@chakra-ui/react"
import { CustomLink, DEFAULT_STATE } from "@components/Link"
import { WishListButton } from "@app/components/WishList/WishListButton"
import { ProductCardBadge, ProductCardImage, ProductCardPrice, ProductCardStars, ProductCardTitle } from "./ProductCardStyles"
import { ProductAddToCart } from "@components/Product/ProductAddToCart"
import { useRoutes } from "@app/hooks/useRoutes"
import { ProductCardVariantSelector } from "./ProductCardVariantSelector"
import { useConfigContext } from "@app/providers/config"
import { useProductCardBadges } from "@app/hooks/useBadges"
import { ProductCardNotifyMe } from "./ProductCardNotifyMe"
import { ProductWriteReview } from "@components/Product/ProductWriteReview"

import type { ProductVariant } from "shopify-storefront-api-typings"
import type { CollectionProps, Location } from "@root/types/global"
import type { WishListButtonProps } from "@components/WishList/WishListButton"

type ProductCardDesktopProps = {
  item: any
  handleTrackingClick: () => void
  selectedVariant: ProductVariant
  location?: Location
  isPlp?: boolean
  isReview?: boolean
  setSelectedVariantIdx: (variantIdx: number) => void
  selectedVariantIdx: number
  isOutOfStock?: boolean
  wishListButtonProps?: WishListButtonProps
  collection?: CollectionProps | GatsbyTypes.SanityCollection | undefined
  productId?: string
  maxWidthDesktop?: string
  subscriptionData?: {
    isSubscriptionProduct: any | undefined
    isSubscriptionCheckout: boolean
    isSubscriptionAndOneTimeProduct: boolean
    subscriptionDiscountPercentage: number | undefined
    subscriptionDinkusMessage: string | undefined
    purchaseRecurrence: "monthly" | "one-time"
    setPurchaseRecurrence: (recurrence: "monthly" | "one-time") => void
  }
}

const ProductCardDesktop: React.FC<ProductCardDesktopProps> = ({
  item,
  handleTrackingClick,
  selectedVariant,
  location,
  isPlp = false,
  isReview = false,
  setSelectedVariantIdx,
  selectedVariantIdx,
  isOutOfStock = false,
  wishListButtonProps,
  collection = null,
  productId,
  maxWidthDesktop,
  subscriptionData,
}) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { urlResolver } = useRoutes()
  const { getProductCardBadges } = useProductCardBadges()
  const { showBadge, badge } = getProductCardBadges({ badgeType: "card", allProductTags: item?.tags })

  const resolvedProductUrl = urlResolver(item, routes.PRODUCT)?.url
  const collectionUrl = urlResolver(collection)?.url

  const handleClick = useCallback(() => handleTrackingClick?.(), [handleTrackingClick])
  const buttonsFontSize = "sm"

  const listPrice = selectedVariant?.compareAtPriceV2?.amount || 0
  const salePrice = selectedVariant?.priceV2?.amount || 0

  let Wrapper = ({ children }: { children: React.ReactNode }) => (
    <CustomLink
      state={collection ? { breadcrumbs: [{ title: collection?.title, url: collectionUrl }] } : DEFAULT_STATE}
      to={resolvedProductUrl}
      title={item?.title}
      onClick={handleClick}
    >
      {children}
    </CustomLink>
  )

  const calculatePercentageDiscount = (listPrice: number, salePrice: number) => {
    const discount = listPrice - salePrice
    const discountPercentage = (discount / listPrice) * 100

    return Math.round(discountPercentage)
  }

  const discountPercentage = listPrice && salePrice ? `Save ${calculatePercentageDiscount(listPrice, salePrice)}%` : null

  if (isReview) {
    Wrapper = ({ children }: { children: React.ReactNode }) => <Box>{children}</Box>
  }

  return (
    <>
      <Wrapper>
        <ProductCardImage
          item={item}
          variant={selectedVariant}
          badgeType="image"
          isOutOfStock={isOutOfStock}
          isReview={isReview}
          maxWidth={maxWidthDesktop}
          discountPercentage={discountPercentage}
        />
        <ProductCardTitle item={item} />
      </Wrapper>

      <ProductCardVariantSelector
        item={item}
        selectedVariantIdx={selectedVariantIdx}
        setSelectedVariantIdx={setSelectedVariantIdx}
        hideLabel
        isPlp={isPlp}
        isSubscriptionAndOneTimeProduct={subscriptionData?.isSubscriptionAndOneTimeProduct}
        recurrence={{
          purchaseRecurrence: subscriptionData?.purchaseRecurrence,
          setPurchaseRecurrence: subscriptionData?.setPurchaseRecurrence,
        }}
        appliedDiscount={subscriptionData?.subscriptionDiscountPercentage}
      />
      <Box mt="auto">
        <ProductCardStars product={item} isPlp={isPlp} productId={productId} />
        <HStack spacing={1} flexWrap="wrap">
          {!isReview && (
            <ProductCardPrice
              variant={selectedVariant}
              appliedDiscount={subscriptionData?.purchaseRecurrence === "monthly" ? subscriptionData?.subscriptionDiscountPercentage : 0}
              isPlp={isPlp}
            />
          )}
          {subscriptionData?.isSubscriptionAndOneTimeProduct ? (
            <ProductCardBadge bgColor="yellow" text={subscriptionData?.subscriptionDinkusMessage} badgeType="card" />
          ) : (
            showBadge && <ProductCardBadge bgColor={badge?.bgColor} text={badge?.text} badgeType="card" />
          )}
        </HStack>
        <Box>
          {isReview && <ProductWriteReview product={item} fontSize={buttonsFontSize} marginBottom={[0, 2, 2]} />}
          {!isReview && isOutOfStock && <ProductCardNotifyMe variant={selectedVariant} fontSize={buttonsFontSize} />}
          {!isReview && !isOutOfStock && (
            <ProductAddToCart
              product={item}
              productId={`${productId}`}
              variant={selectedVariant}
              fontSize={buttonsFontSize}
              marginBottom={[0, 2, 2]}
              subscriptionData={{
                isSubscriptionAndOneTimeProduct: subscriptionData?.isSubscriptionAndOneTimeProduct,
                purchaseRecurrence: subscriptionData?.purchaseRecurrence,
                subscriptionDiscountPercentage: subscriptionData?.subscriptionDiscountPercentage,
              }}
              isSubscriptionProduct={
                subscriptionData?.isSubscriptionAndOneTimeProduct
                  ? subscriptionData?.isSubscriptionCheckout
                    ? subscriptionData?.isSubscriptionProduct
                    : false
                  : subscriptionData?.isSubscriptionProduct
              }
            />
          )}
        </Box>
        {!isReview && (
          <WishListButton
            product={item}
            variant={selectedVariant}
            location={location}
            fontSize={buttonsFontSize}
            height={10}
            iconWidth={16}
            {...wishListButtonProps}
          />
        )}
      </Box>
    </>
  )
}

export default memo(ProductCardDesktop)
