import { memo, useCallback } from "react"
import { Box, Flex, HStack } from "@chakra-ui/react"
import { CustomLink } from "@components/Link"
import { WishListButton } from "@app/components/WishList/WishListButton"
import { ProductCardBadge, ProductCardImage, ProductCardPrice, ProductCardStars, ProductCardTitle } from "./ProductCardStyles"
import { ProductAddToCart } from "@components/Product/ProductAddToCart"
import { ProductCardVariantSelector } from "./ProductCardVariantSelector"
import { useProductCardBadges } from "@app/hooks/useBadges"
import { ProductCardNotifyMe } from "./ProductCardNotifyMe"
import { useConfigContext } from "@app/providers/config"
import { useRoutes } from "@app/hooks/useRoutes"
import { ProductWriteReview } from "@components/Product/ProductWriteReview"

import type { ProductVariant } from "shopify-storefront-api-typings"
import type { Location } from "@root/types/global"
import type { WishListButtonProps } from "@components/WishList/WishListButton"

type ProductCardMobileProps = {
  item: any
  handleTrackingClick: () => void
  selectedVariant: ProductVariant
  location?: Location
  isPlp?: boolean
  isReview?: boolean
  setSelectedVariantIdx: (variantIdx: number) => void
  selectedVariantIdx: number
  isOutOfStock?: boolean
  wishListButtonProps?: WishListButtonProps
  productId?: string
  maxWidthMobile?: string
  disableLazyLoad?: boolean
  subscriptionData?: {
    isSubscriptionProduct: any | undefined
    isSubscriptionCheckout: boolean
    isSubscriptionAndOneTimeProduct: boolean
    subscriptionDiscountPercentage: number | undefined
    subscriptionDinkusMessage: string | undefined
    purchaseRecurrence: "monthly" | "one-time"
    setPurchaseRecurrence: (recurrence: "monthly" | "one-time") => void
  }
}

const ProductCardMobile: React.FC<ProductCardMobileProps> = ({
  item,
  handleTrackingClick,
  selectedVariant,
  location,
  isPlp = false,
  isReview = false,
  setSelectedVariantIdx,
  selectedVariantIdx,
  isOutOfStock = false,
  wishListButtonProps,
  productId,
  maxWidthMobile,
  disableLazyLoad = false,
  subscriptionData,
}) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { urlResolver } = useRoutes()
  const { getProductCardBadges } = useProductCardBadges()
  const { showBadge, badge } = getProductCardBadges({ badgeType: "card", allProductTags: item?.tags })
  const resolvedProductUrl = item?.link?.url || urlResolver(item, routes.PRODUCT)?.url
  const handleClick = useCallback(() => handleTrackingClick?.(), [handleTrackingClick])

  const listPrice = selectedVariant?.compareAtPriceV2?.amount || 0
  const salePrice = selectedVariant?.priceV2?.amount || 0

  const calculatePercentageDiscount = (listPrice: number, salePrice: number) => {
    const discount = listPrice - salePrice
    const discountPercentage = (discount / listPrice) * 100

    return Math.round(discountPercentage)
  }

  const discountPercentage = listPrice && salePrice ? `Save ${calculatePercentageDiscount(listPrice, salePrice)}%` : null

  let Wrapper = ({ children }: { children: React.ReactNode }) => (
    <CustomLink
      state={{ breadcrumbs: [{ title: item?.title, url: resolvedProductUrl }] }}
      to={resolvedProductUrl}
      title={item?.title}
      onClick={handleClick}
    >
      {children}
    </CustomLink>
  )

  if (isReview) {
    Wrapper = ({ children }: { children: React.ReactNode }) => <Box>{children}</Box>
  }

  return (
    <HStack alignItems="stretch" w="full" spacing={6} pt={2} px={2} pb={isPlp ? 6 : 2} borderBottom={isPlp ? "1px" : "none"}>
      <Flex w={34} minW={34} flexDir="column" justifyContent="space-between">
        <Wrapper>
          <ProductCardImage
            item={item}
            variant={selectedVariant}
            badgeType="image"
            isOutOfStock={isOutOfStock}
            isReview={isReview}
            maxWidth={maxWidthMobile}
            disableLazyLoad={disableLazyLoad}
            discountPercentage={discountPercentage}
          />
        </Wrapper>
        <Box mt={3.5}>
          {!isReview && (
            <WishListButton
              variant={selectedVariant}
              product={item}
              location={location}
              fontSize="sm"
              height={10}
              iconWidth={16}
              {...wishListButtonProps}
            />
          )}
        </Box>
      </Flex>
      <Flex flexGrow={1} flexDir="column" justifyContent="space-between" ml={4} h="full">
        <Box>
          <Wrapper>
            <ProductCardTitle item={item} />
          </Wrapper>
          <ProductCardVariantSelector
            item={item}
            selectedVariantIdx={selectedVariantIdx}
            setSelectedVariantIdx={setSelectedVariantIdx}
            isPlp={isPlp}
            hideLabel
            isSubscriptionAndOneTimeProduct={subscriptionData?.isSubscriptionAndOneTimeProduct}
            recurrence={{
              purchaseRecurrence: subscriptionData?.purchaseRecurrence,
              setPurchaseRecurrence: subscriptionData?.setPurchaseRecurrence,
            }}
            appliedDiscount={subscriptionData?.subscriptionDiscountPercentage}
          />
          <ProductCardStars product={item} isPlp={isPlp} productId={productId} />
          <HStack spacing={0} alignItems="center" position="relative" flexWrap="wrap" gap="5px">
            {!isReview && (
              <ProductCardPrice
                variant={selectedVariant}
                appliedDiscount={subscriptionData?.purchaseRecurrence === "monthly" ? subscriptionData?.subscriptionDiscountPercentage : 0}
                isPlp={isPlp}
              />
            )}
            {subscriptionData?.isSubscriptionAndOneTimeProduct ? (
              <ProductCardBadge bgColor="yellow" text={subscriptionData?.subscriptionDinkusMessage} badgeType="card" />
            ) : (
              showBadge && <ProductCardBadge bgColor={badge?.bgColor} text={badge?.text} badgeType="card" />
            )}
          </HStack>
        </Box>
        <Box mt={2}>
          {isReview && <ProductWriteReview product={item} />}
          {!isReview && isOutOfStock && <ProductCardNotifyMe variant={selectedVariant} />}
          {!isReview && !isOutOfStock && (
            <ProductAddToCart
              product={item}
              productId={`${productId}`}
              variant={selectedVariant}
              subscriptionData={{
                isSubscriptionAndOneTimeProduct: subscriptionData?.isSubscriptionAndOneTimeProduct,
                purchaseRecurrence: subscriptionData?.purchaseRecurrence,
                subscriptionDiscountPercentage: subscriptionData?.subscriptionDiscountPercentage,
              }}
              isSubscriptionProduct={
                subscriptionData?.isSubscriptionAndOneTimeProduct
                  ? subscriptionData?.isSubscriptionCheckout
                    ? subscriptionData?.isSubscriptionProduct
                    : false
                  : subscriptionData?.isSubscriptionProduct
              }
            />
          )}
        </Box>
      </Flex>
    </HStack>
  )
}

export default memo(ProductCardMobile)
